import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

interface GameCardProps {
  id: string;
  name: string;
  image: string;
  bg: string;
}

const GameCard = ({ id, name, image, bg }: GameCardProps) => {
  const [imageError, setImageError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setTimeout(() => setIsVisible(true), 100);
        }
      },
      { threshold: 0.1 }
    );
    
    if (cardRef.current) {
      observer.observe(cardRef.current);
    }
    
    return () => observer.disconnect();
  }, []);
  
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isHovering) return;
    
    const rect = e.currentTarget.getBoundingClientRect();
    
    // Calculate mouse position relative to card center
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const mouseX = e.clientX - centerX;
    const mouseY = e.clientY - centerY;
    
    // Calculate rotation (max 15 degrees)
    const rotateX = (mouseY / (rect.height / 2)) * -10;
    const rotateY = (mouseX / (rect.width / 2)) * 10;
    
    setMousePosition({ x: rotateY, y: rotateX });
  };
  
  return (
    <div 
      ref={cardRef}
      className={`perspective-1000 transition-all duration-1000 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-20'}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => {
        setIsHovering(false);
        setMousePosition({ x: 0, y: 0 });
      }}
      onMouseMove={handleMouseMove}
    >
      <div 
        className={`relative rounded-xl overflow-hidden h-full transform-gpu transition-all duration-300 ease-out bg-opacity-70 backdrop-blur-sm ${bg} border-2 border-white/20 shadow-2xl shadow-purple-500/20 group hover:shadow-purple-500/40`}
        style={{ 
          transform: `rotateY(${mousePosition.x}deg) rotateX(${mousePosition.y}deg) scale(${isHovering ? 1.03 : 1})`,
          transition: isHovering ? 'transform 0.1s ease-out' : 'transform 0.5s ease-out'
        }}
      >
        {/* Card Inner */}
        <div className="p-6 flex flex-col items-center justify-between h-full relative z-10">
          {/* Floating orbs background */}
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute -top-8 -left-8 w-16 h-16 rounded-full bg-purple-500/20 animate-float"></div>
            <div className="absolute top-1/3 -right-8 w-20 h-20 rounded-full bg-indigo-500/20 animate-float-slow"></div>
            <div className="absolute -bottom-10 left-1/4 w-24 h-24 rounded-full bg-blue-500/20 animate-pulse-slow"></div>
          </div>
          
          {/* Glow effects */}
          <div className="absolute -inset-2 bg-gradient-to-r from-pink-500/30 via-purple-500/30 to-indigo-500/30 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-700 -z-10"></div>
          
          {/* Name Badge - appears on hover */}
          <div className="absolute -top-5 left-1/2 transform -translate-x-1/2 bg-indigo-600/80 backdrop-blur-md px-4 py-1 rounded-full border border-white/20 shadow-lg opacity-0 group-hover:opacity-100 group-hover:top-3 transition-all duration-300 z-20">
            <span className="text-white font-medium text-sm">{name}</span>
          </div>
          
          {/* Game Image Link */}
          <Link to={`/games/${id}`} className="w-full flex-1 flex items-center justify-center relative overflow-hidden rounded-lg mt-6">
            <div className="absolute inset-0 bg-black/20 backdrop-blur-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10 flex items-center justify-center">
              <div className="px-5 py-3 bg-white/20 backdrop-blur-md rounded-full opacity-0 group-hover:opacity-100 transform group-hover:scale-100 scale-50 transition-all duration-300 border border-white/30 shadow-xl text-white font-bold">
                <span className="relative inline-block">
                  Play Now
                  <span className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-transparent via-white to-transparent transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-center"></span>
                </span>
              </div>
            </div>
            
            <div className="flex-1 flex items-center justify-center w-full">
              {!imageError ? (
                <div className="relative w-[200px] h-[120px] transform-gpu transition-all duration-500 group-hover:scale-110">
                  <img
                    src={image}
                    alt={`${name} Game`}
                    className="w-full h-full object-contain z-0"
                    onError={() => setImageError(true)}
                  />
                  
                  {/* Reflection effect */}
                  <div 
                    className="absolute inset-0 opacity-30 bg-gradient-to-b from-transparent via-transparent to-white/20"
                    style={{ transform: 'translateY(50%) scaleY(-1) perspective(500px) rotateX(30deg)' }}
                  ></div>
                  
                  {/* Interactive Glow */}
                  <div 
                    className="absolute inset-0 bg-gradient-radial from-white/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg"
                    style={{ 
                      background: `radial-gradient(circle at ${50 + mousePosition.x * 3}% ${50 - mousePosition.y * 3}%, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0) 60%)` 
                    }}
                  ></div>
                </div>
              ) : (
                <div className="w-[200px] h-[120px] flex items-center justify-center bg-gradient-to-r from-pink-500 to-purple-500 rounded-lg shadow-inner text-3xl font-bold text-white transition-transform duration-300 group-hover:scale-110 relative">
                  {name.charAt(0)}
                  
                  {/* Dynamic gradient */}
                  <div className="absolute inset-0 bg-gradient-to-r from-pink-400/0 via-white/20 to-pink-400/0 rounded-lg animate-shine"></div>
                  
                  {/* Particle effect */}
                  <div className="absolute inset-0 overflow-hidden">
                    <div className="w-2 h-2 rounded-full bg-white/60 absolute top-1/4 left-1/4 animate-float"></div>
                    <div className="w-3 h-3 rounded-full bg-white/40 absolute top-3/4 right-1/3 animate-float-slow"></div>
                    <div className="w-2 h-2 rounded-full bg-white/60 absolute bottom-1/4 right-1/4 animate-pulse-slow"></div>
                  </div>
                </div>
              )}
            </div>
          </Link>
          
          {/* Game Title */}
          <h3 className="text-xl font-bold text-white mt-4 text-center text-shadow">
            {name}
          </h3>
          
          {/* Play Button */}
          <Link to={`/games/${id}`} className="mt-4 w-full">
            <button className="w-full group-hover:scale-105 bg-gradient-to-r from-green-400 to-emerald-500 hover:from-green-500 hover:to-emerald-600 text-white font-bold py-3 px-6 rounded-full transition-all duration-300 shadow-lg shadow-green-500/30 hover:shadow-green-500/50 relative overflow-hidden">
              {/* Button shine effect */}
              <div className="absolute inset-0 w-1/3 bg-white/20 skew-x-12 transform -translate-x-full group-hover:animate-shimmer"></div>
              
              <span className="relative z-10 flex items-center justify-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 animate-pulse-slow" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                </svg>
                Play Now!
              </span>
              
              {/* Button Glow */}
              <div className="absolute -inset-1 rounded-full bg-gradient-to-r from-green-400/0 via-green-400/70 to-green-400/0 opacity-0 group-hover:opacity-100 blur-md transition-opacity duration-500"></div>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GameCard; 