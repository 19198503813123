import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSection from '../components/sections/HeroSection';
import GamesSection from '../components/sections/GamesSection';
import AboutSection from '../components/sections/AboutSection';
import DisclaimerSection from '../components/sections/DisclaimerSection';

function Home() {
  return (
    <>
      <Helmet>
        <title>Game-IO | Free Social Casino Games</title>
        <meta name="description" content="Play the best free social casino games online. Game-IO offers the most exciting social gaming experience with a wide variety of games to choose from!" />
        <meta property="og:title" content="Game-IO | Free Social Casino Games" />
        <meta property="og:description" content="Play the best free social casino games online. Game-IO offers the most exciting social gaming experience with a wide variety of games to choose from!" />
        <meta property="og:url" content="https://game-io.com" />
        <meta property="og:type" content="website" />
      </Helmet>
      <HeroSection />
      <GamesSection />
      <AboutSection />
      <DisclaimerSection />
    </>
  );
}

export default Home; 