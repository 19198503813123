import React from 'react';
import { Helmet } from 'react-helmet-async';

function Terms() {
  return (
    <>
      <Helmet>
        <title>Terms of Service - Game-IO Free Social Casino</title>
        <meta name="description" content="Read our Terms of Service to understand the rules, guidelines, and agreements that govern your use of Game-IO's free social casino gaming platform." />
        <meta property="og:title" content="Terms of Service - Game-IO Free Social Casino" />
        <meta property="og:description" content="Read our Terms of Service to understand the rules, guidelines, and agreements that govern your use of Game-IO's free social casino gaming platform." />
        <meta property="og:url" content="https://game-io.com/terms" />
        <meta property="og:type" content="website" />
      </Helmet>
      
      <main className="min-h-screen bg-indigo-700 pt-24">
        <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-20 py-16 md:py-24">
          <div className="text-center mb-12">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-yellow-300 mb-6">
              Terms of Service
            </h1>
            <p className="text-white text-lg max-w-3xl mx-auto">
              Last Updated: March 24, 2025
            </p>
          </div>
          
          <div className="bg-indigo-700 rounded-2xl p-8 md:p-10 shadow-xl">
            <div className="prose prose-lg max-w-none text-white">
              <h2 className="text-2xl font-bold text-yellow-300 mb-4">Terms and Conditions For Game-IO</h2>
              
              <h3 className="text-xl font-bold text-yellow-200 mt-8 mb-4">Introduction</h3>
              <p>
                By accessing or using the services provided by Game-IO, you agree to be bound by the following Terms and Conditions. These terms govern your use of our platform and the free social casino games available on it. If you do not agree with these terms, please refrain from using our services.
              </p>
              
              <h3 className="text-xl font-bold text-yellow-200 mt-8 mb-4">Eligibility</h3>
              <p>
                You must be at least 18 years of age to use our platform. By registering and using our services, you confirm that you are legally eligible to access and participate in the free social casino games provided by Game-IO. If we discover that you do not meet the age requirement, we may suspend or terminate your account.
              </p>
              
              <h3 className="text-xl font-bold text-yellow-200 mt-8 mb-4">Account Registration</h3>
              <p>
                To access the services, you must create an account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials, and you agree to notify us immediately if you believe your account has been compromised.
              </p>
              
              <h3 className="text-xl font-bold text-yellow-200 mt-8 mb-4">Use of the Platform</h3>
              <p>
                You agree to use the platform only for lawful purposes and in accordance with these Terms and Conditions. You may not engage in any activity that disrupts the normal operation of the platform, including the use of cheats, bots, or other unfair practices.
              </p>
              
              <h3 className="text-xl font-bold text-yellow-200 mt-8 mb-4">Free Social Casino Game Content</h3>
              <p>
                All content within the free social casino games, including but not limited to graphics, text, and gameplay features, is the intellectual property of Game-IO and is protected by copyright and other intellectual property laws. You agree not to copy, distribute, or exploit any of this content without prior written consent.
              </p>
              
              <h3 className="text-xl font-bold text-yellow-200 mt-8 mb-4">No Real Money Transactions</h3>
              <p>
                Our platform does not involve any real-world monetary transactions, and all in-game activities are for entertainment purposes only. There are no real-world financial rewards associated with any of our free social casino games.
              </p>
              
              <h3 className="text-xl font-bold text-yellow-200 mt-8 mb-4">Termination of Account</h3>
              <p>
                We reserve the right to suspend or terminate your account at any time if we suspect any violation of these Terms and Conditions. In the event of termination, you may lose access to your account and any virtual content associated with it.
              </p>
              
              <h3 className="text-xl font-bold text-yellow-200 mt-8 mb-4">Limitation of Liability</h3>
              <p>
                Game-IO is not liable for any direct or indirect damage or loss resulting from your use of the platform or participation in the free social casino games. You acknowledge that playing on our platform is at your own risk.
              </p>
              
              <h3 className="text-xl font-bold text-yellow-200 mt-8 mb-4">Changes to Terms and Conditions</h3>
              <p>
                We may update these Terms and Conditions from time to time. Any changes will be posted on our website, and the new terms will take effect upon posting. We encourage you to review these terms periodically.
              </p>
              
              <h3 className="text-xl font-bold text-yellow-200 mt-8 mb-4">User Conduct</h3>
              <p>
                When using our platform, you agree not to:
              </p>
              <ul className="list-disc pl-6 mb-6">
                <li>Violate any applicable laws or regulations</li>
                <li>Infringe upon the rights of others</li>
                <li>Use the platform for any illegal activities</li>
                <li>Attempt to gain unauthorized access to any part of our services</li>
                <li>Interfere with the proper functioning of the platform</li>
                <li>Share offensive, harmful, or inappropriate content</li>
              </ul>
              
              <div className="mt-12 pt-8 border-t border-indigo-500">
                <p className="text-indigo-200">
                  If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:info@game-io.com" className="text-yellow-300 hover:underline">info@game-io.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Terms; 