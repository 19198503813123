import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

export default function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    
    // Set active link based on current path
    const path = location.pathname;
    setActiveLink(path === '/' ? '/' : path.split('/')[1]);
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location]);
  
  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-500 ${
      scrolled 
        ? 'py-2 backdrop-blur-xl bg-indigo-900/70 shadow-2xl' 
        : 'py-4 bg-gradient-to-r from-indigo-900 to-indigo-800'
    }`}>
      <div className="absolute inset-0 overflow-hidden">
        {/* Decorative elements */}
        <div className="absolute top-0 left-1/4 w-64 h-64 bg-purple-500/20 rounded-full blur-3xl -translate-y-1/2"></div>
        <div className="absolute top-0 right-1/3 w-56 h-56 bg-blue-500/20 rounded-full blur-3xl -translate-y-1/2"></div>
      </div>
      
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-20 relative">
        <div className="flex justify-between items-center">
          <Link to="/" className="group relative flex items-center">
            {/* Logo glow effect */}
            <div className="absolute -inset-2 rounded-full bg-gradient-to-r from-yellow-300/0 via-yellow-300/40 to-yellow-300/0 opacity-0 group-hover:opacity-100 blur-xl transition-opacity duration-500"></div>
            
            <div className="relative">
              <img 
                src="/logo.png" 
                alt="GAME-IO CASINO" 
                width={150} 
                height={40} 
                className={`object-contain h-12 transition-transform duration-300 ${scrolled ? 'scale-90' : 'scale-100'}`}
              />
            </div>
          </Link>
          
          <nav className="hidden md:flex gap-8">
            <Link 
              to="/" 
              className={`relative group overflow-hidden px-3 py-2 transition-colors text-lg font-medium ${
                activeLink === '/' 
                  ? 'text-yellow-300' 
                  : 'text-white hover:text-yellow-300'
              }`}
            >
              <span className="relative z-10">Home</span>
              
              {/* Active/Hover indicator */}
              <span className={`absolute bottom-0 left-0 w-full h-0.5 ${
                activeLink === '/' 
                  ? 'bg-gradient-to-r from-yellow-300 to-yellow-500' 
                  : 'bg-white/50 scale-x-0 group-hover:scale-x-100'
              } transition-transform duration-300 origin-left`}></span>
              
              {/* Hover glow */}
              <span className="absolute inset-0 bg-white/5 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
            </Link>
            
            <Link 
              to="/about" 
              className={`relative group overflow-hidden px-3 py-2 transition-colors text-lg font-medium ${
                activeLink === 'about' 
                  ? 'text-yellow-300' 
                  : 'text-white hover:text-yellow-300'
              }`}
            >
              <span className="relative z-10">About</span>
              
              {/* Active/Hover indicator */}
              <span className={`absolute bottom-0 left-0 w-full h-0.5 ${
                activeLink === 'about' 
                  ? 'bg-gradient-to-r from-yellow-300 to-yellow-500' 
                  : 'bg-white/50 scale-x-0 group-hover:scale-x-100'
              } transition-transform duration-300 origin-left`}></span>
              
              {/* Hover glow */}
              <span className="absolute inset-0 bg-white/5 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
            </Link>
            
            <Link 
              to="/games" 
              className={`relative group overflow-hidden px-3 py-2 transition-colors text-lg font-medium ${
                activeLink === 'games' 
                  ? 'text-yellow-300' 
                  : 'text-white hover:text-yellow-300'
              }`}
            >
              <span className="relative z-10">Games</span>
              
              {/* Active/Hover indicator */}
              <span className={`absolute bottom-0 left-0 w-full h-0.5 ${
                activeLink === 'games' 
                  ? 'bg-gradient-to-r from-yellow-300 to-yellow-500' 
                  : 'bg-white/50 scale-x-0 group-hover:scale-x-100'
              } transition-transform duration-300 origin-left`}></span>
              
              {/* Hover glow */}
              <span className="absolute inset-0 bg-white/5 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
            </Link>
            
            <Link 
              to="/contact" 
              className={`relative group overflow-hidden px-3 py-2 transition-colors text-lg font-medium ${
                activeLink === 'contact' 
                  ? 'text-yellow-300' 
                  : 'text-white hover:text-yellow-300'
              }`}
            >
              <span className="relative z-10">Contact</span>
              
              {/* Active/Hover indicator */}
              <span className={`absolute bottom-0 left-0 w-full h-0.5 ${
                activeLink === 'contact' 
                  ? 'bg-gradient-to-r from-yellow-300 to-yellow-500' 
                  : 'bg-white/50 scale-x-0 group-hover:scale-x-100'
              } transition-transform duration-300 origin-left`}></span>
              
              {/* Hover glow */}
              <span className="absolute inset-0 bg-white/5 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
            </Link>
          </nav>
          
          {/* Mobile menu button */}
          <button 
            className="md:hidden p-2 text-white hover:text-yellow-300 transition-colors z-50"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </button>
          
          {/* Mobile menu */}
          <div className={`fixed inset-0 bg-indigo-900/95 backdrop-blur-xl z-40 transition-all duration-300 flex flex-col items-center justify-center gap-8 ${
            isMobileMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
          }`}>
            <Link 
              to="/" 
              className="text-2xl font-bold text-white hover:text-yellow-300 transition-colors"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Home
            </Link>
            <Link 
              to="/about" 
              className="text-2xl font-bold text-white hover:text-yellow-300 transition-colors"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              About
            </Link>
            <Link 
              to="/games" 
              className="text-2xl font-bold text-white hover:text-yellow-300 transition-colors"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Games
            </Link>
            <Link 
              to="/contact" 
              className="text-2xl font-bold text-white hover:text-yellow-300 transition-colors"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Contact
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
} 