import React, { useState, useEffect } from 'react';

export default function AboutHero() {
  const [scrollY, setScrollY] = useState(0);
  
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="relative h-[70vh] overflow-hidden">
      {/* Background Elements */}
      <div 
        className="absolute inset-0 bg-gradient-to-r from-indigo-900 via-purple-900 to-indigo-800 transform"
        style={{ transform: `translateY(${scrollY * 0.1}px)` }}
      ></div>
      
      <div className="absolute inset-0">
        {/* Floating Elements */}
        <div className="absolute top-[10%] left-[10%] w-32 h-32 rounded-full bg-pink-500 opacity-20 blur-xl"
              style={{ transform: `translate(${scrollY * 0.05}px, ${scrollY * -0.05}px)` }}></div>
        <div className="absolute top-[30%] right-[20%] w-40 h-40 rounded-full bg-purple-500 opacity-20 blur-xl"
              style={{ transform: `translate(${scrollY * -0.08}px, ${scrollY * 0.03}px)` }}></div>
        <div className="absolute bottom-[20%] left-[30%] w-36 h-36 rounded-full bg-blue-500 opacity-20 blur-xl"
              style={{ transform: `translate(${scrollY * 0.1}px, ${scrollY * -0.04}px)` }}></div>
              
        {/* Grid Pattern */}
        <div className="absolute inset-0 opacity-10"
              style={{ 
                backgroundImage: 'linear-gradient(to right, white 1px, transparent 1px), linear-gradient(to bottom, white 1px, transparent 1px)',
                backgroundSize: '60px 60px',
                transform: `translateY(${scrollY * 0.2}px)` 
              }}></div>
      </div>
      
      {/* Content */}
      <div className="relative z-10 flex items-center justify-center h-full px-6 md:px-12 xl:px-20">
        <div className="max-w-7xl mx-auto text-center"
              style={{ transform: `translateY(${scrollY * -0.15}px)` }}>
          <h1 
            className="text-5xl md:text-6xl lg:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-yellow-500 mb-6 tracking-tight"
            style={{ transform: `translateY(${scrollY * -0.1}px)` }}
          >
            About Us
          </h1>
          <p 
            className="text-white text-xl md:text-2xl max-w-4xl mx-auto font-light"
            style={{ transform: `translateY(${scrollY * -0.05}px)` }}
          >
            Learn more about Game-IO and why we're the best destination for free social casino gaming
          </p>
          
          <div className="mt-12 flex justify-center gap-4"
                style={{ transform: `translateY(${scrollY * -0.2}px)` }}>
            <div className="w-3 h-12 bg-gradient-to-b from-yellow-300 to-yellow-500 rounded-full animate-bounce-slow"></div>
            <div className="w-3 h-16 bg-gradient-to-b from-yellow-300 to-yellow-500 rounded-full animate-bounce-delayed"></div>
            <div className="w-3 h-8 bg-gradient-to-b from-yellow-300 to-yellow-500 rounded-full animate-bounce-slower"></div>
          </div>
        </div>
      </div>
      
      {/* Bottom Fade Overlay */}
      <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-indigo-600 to-transparent"></div>
    </div>
  );
} 