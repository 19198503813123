import React from 'react';
import GameCard from '../GameCard';

// Mock of gamesContent from the JSON file
const gamesContent = {
  title: "OUR TOP FREE SOCIAL CASINO GAME",
  description: "Numerous free social casino games are available on our platform, all of them are intended to provide hours of fun and diversity. Whether they have new twists or classics, everyone may find something they like in our range. Enjoy yourself while taking part in a vibrant free social casino gaming the area.",
  featuredGames: [
    {
      id: "pop",
      name: "POP",
      image: "/games/jpop.webp",
      bg: "bg-blue-400",
      iframe:"https://gamelauncher-stage.contentmedia.eu/1.0/game/?gameid=10203&operatorid=44&mode=demo&currency=EUR&device=desktop&token=EUR_1597926622061&language=en_gb&xdm=1",
      featured: true
    },
    {
      id: "slurpy",
      name: "SLURPY",
      image: "/games/slurpy.webp",
      bg: "bg-purple-400",
      iframe:"https://gamelauncher-stage.contentmedia.eu/1.0/game/?gameid=10220&operatorid=44&mode=demo&currency=EUR&device=desktop&token=EUR_1597926622061&language=en_gb&xdm=1",
      featured: true
    },
    {
      id: "squeeze",
      name: "SQUEEZE",
      image: "/games/squeeze.webp",
      bg: "bg-blue-400",
      iframe:"https://gamelauncher-stage.contentmedia.eu/1.0/game/?gameid=10218&operatorid=44&mode=demo&currency=EUR&device=desktop&token=EUR_1597926622061&language=en_gb&xdm=1",
      featured: true
    },
    {
      id: "oxygen",
      name: "OXYGEN",
      image: "/games/oxygen.webp",
      bg: "bg-purple-600",
      iframe:"https://gamelauncher-stage.contentmedia.eu/1.0/game/?gameid=10206&operatorid=44&mode=demo&currency=EUR&device=desktop&token=EUR_1597926622061&language=en_gb&xdm=1",
      featured: false
    },
    {
      id: "valhalla-gold",
      name: "VALHALLA GOLD",
      image: "/games/valhalla-gold.webp",
      bg: "bg-purple-600",
      iframe:"https://gamelauncher-stage.contentmedia.eu/1.0/game/?gameid=10201&operatorid=44&mode=demo&currency=EUR&device=desktop&token=EUR_1597926622061&language=en_gb&xdm=1",
      featured: false
    },
    {
      id: "katmandu",
      name: "KATMANDU",
      image: "/games/katmandu.webp",
      bg: "bg-purple-600",
      iframe:"https://gamelauncher-stage.contentmedia.eu/1.0/game/?gameid=10192&operatorid=44&mode=demo&currency=EUR&device=desktop&token=EUR_1597926622061&language=en_gb&xdm=1",
      featured: false
    }
  ]
};

const GamesSection = () => {
  const featuredGames = gamesContent.featuredGames.filter(game => game.featured);
  const regularGames = gamesContent.featuredGames.filter(game => !game.featured);

  return (
    <section className="py-16 md:py-24 relative overflow-hidden">
      {/* Modern Background Elements */}
      <div className="absolute inset-0 bg-gradient-to-br from-indigo-900 via-purple-800 to-blue-900 z-0"></div>
      
      {/* Grid Pattern */}
      <div className="absolute inset-0 z-0 opacity-5">
        <div className="h-full w-full"
             style={{ 
               backgroundImage: 'linear-gradient(to right, white 1px, transparent 1px), linear-gradient(to bottom, white 1px, transparent 1px)',
               backgroundSize: '40px 40px'
             }}>
        </div>
      </div>
      
      {/* Floating Elements */}
      <div className="absolute top-[10%] right-[10%] w-48 h-48 rounded-full bg-blue-500 opacity-20 blur-3xl animate-float-slow"></div>
      <div className="absolute bottom-[20%] left-[15%] w-64 h-64 rounded-full bg-purple-500 opacity-20 blur-3xl animate-float"></div>
      <div className="absolute top-[40%] left-[30%] w-32 h-32 rounded-full bg-cyan-500 opacity-15 blur-2xl animate-pulse-slow"></div>
      
      {/* Content */}
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-20 relative z-10">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-yellow-500 leading-tight tracking-wide mb-6">
            {gamesContent.title}
          </h2>
          <p className="text-white text-lg max-w-4xl mx-auto leading-relaxed">
            {gamesContent.description}
          </p>
        </div>

        {/* Featured Games - Row 1 */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          {featuredGames.map(game => (
            <GameCard
              key={game.id}
              id={game.id}
              name={game.name}
              image={game.image}
              bg={game.bg}
            />
          ))}
        </div>

        {/* Regular Games - Row 2 */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
          {regularGames.map(game => (
            <GameCard
              key={game.id}
              id={game.id}
              name={game.name}
              image={game.image}
              bg={game.bg}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default GamesSection; 