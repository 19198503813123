import React, { useState, useEffect } from 'react';

// Mock of aboutContent from the JSON file
const aboutContent = {
  title: "ABOUT TO FREE SOCIAL CASINO GAME",
  paragraphs: [
    "Find a platform that will provide you with hours of delightful immersive entertainment. Discover a range of taking part free social casino games providing new and exciting experiences each time you play. Discover the action of free social casino gaming in a kind, clean setting where enjoyment and amusement are the only priorities. Discover new features and take delight in countless opportunities in a fun-filled environment.",
    "There is something that fits your vibe, whether you want to plunge into something lengthier or are just in the mood for a brief session. There's always something new to keep you interested with frequent updates and fascinating new features."
  ],
  image: {
    src: "/character-round.png",
    alt: "Casino Character in Round Frame"
  }
};

const AboutSection = () => {
  const [hasImage, setHasImage] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="py-24 md:py-32 bg-gradient-to-b from-indigo-600 to-indigo-800 relative overflow-hidden">
      {/* Decorative elements */}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden opacity-20 pointer-events-none">
        <div className="absolute top-[10%] left-[5%] w-64 h-64 rounded-full bg-pink-500 filter blur-3xl animate-blob"></div>
        <div className="absolute top-[40%] right-[5%] w-72 h-72 rounded-full bg-purple-500 filter blur-3xl animate-blob animation-delay-2000"></div>
        <div className="absolute bottom-[10%] left-[35%] w-60 h-60 rounded-full bg-blue-500 filter blur-3xl animate-blob animation-delay-4000"></div>
      </div>
      
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-20 relative z-10">
        <div className="flex flex-col md:flex-row items-center justify-between gap-16">
          {/* Left Column - Text Content */}
          <div className={`md:w-1/2 space-y-10 transition-all duration-1000 ${isVisible ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-10'}`}>
            <div className="inline-block relative">
              <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-200 to-yellow-400 leading-tight tracking-wide drop-shadow-sm">
                {aboutContent.title}
              </h2>
              <div className="absolute -bottom-2 left-0 w-1/3 h-1 bg-gradient-to-r from-yellow-400 to-transparent rounded-full"></div>
            </div>
            
            <div className="space-y-8">
              {aboutContent.paragraphs.map((paragraph, index) => (
                <div 
                  key={index} 
                  className={`backdrop-blur-sm bg-white/10 p-6 rounded-xl border border-white/20 shadow-xl transition-all duration-1000 delay-${index * 300} transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
                >
                  <p className="text-white text-lg leading-relaxed">
                    {paragraph}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Right Column - Image */}
          <div className={`md:w-1/2 flex justify-center md:justify-end transition-all duration-1000 delay-500 ${isVisible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'}`}>
            <div className="relative group">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-pink-500 to-purple-600 rounded-full opacity-75 blur-xl group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-pulse-slow"></div>
              
              <div className="relative w-[280px] h-[280px] sm:w-[350px] sm:h-[350px] md:w-[400px] md:h-[400px] rounded-full overflow-hidden border-8 border-white/30 shadow-2xl bg-gradient-to-r from-pink-400 to-purple-500 transform transition duration-500 hover:scale-105 hover:rotate-3">
                {hasImage ? (
                  <img
                    src={aboutContent.image.src}
                    alt={aboutContent.image.alt}
                    className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110 group-hover:rotate-3"
                    onError={() => setHasImage(false)}
                  />
                ) : (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="w-3/4 h-3/4 bg-white/90 rounded-full overflow-hidden flex flex-col items-center justify-center relative">
                      {/* 3D character face */}
                      <div className="absolute w-28 h-28 rounded-full bg-gradient-to-b from-pink-200 to-pink-300 top-12 shadow-lg"></div>
                      <div className="absolute w-9 h-9 rounded-full bg-black top-20 left-[calc(50%-24px)] shadow-inner"></div>
                      <div className="absolute w-9 h-9 rounded-full bg-black top-20 left-[calc(50%+14px)] shadow-inner"></div>
                      <div className="absolute w-4 h-4 rounded-full bg-white top-21 left-[calc(50%-20px)] opacity-70"></div>
                      <div className="absolute w-4 h-4 rounded-full bg-white top-21 left-[calc(50%+18px)] opacity-70"></div>
                      <div className="absolute w-16 h-8 rounded-full bg-gradient-to-r from-pink-300 to-pink-400 top-36 shadow-sm"></div>
                      <div className="absolute w-36 h-20 bg-gradient-to-b from-blue-400 to-blue-500 bottom-12 rounded-t-lg shadow-md"></div>
                    </div>
                  </div>
                )}
              </div>
              
              {/* Floating badges */}
              <div className="absolute -top-6 -right-6 bg-gradient-to-r from-green-400 to-blue-500 rounded-full p-4 shadow-lg animate-float-slow">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              
              <div className="absolute -bottom-4 -left-4 bg-gradient-to-r from-purple-500 to-indigo-500 rounded-full p-3 shadow-lg animate-float">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection; 