import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  const [hasCharacterImage, setHasCharacterImage] = useState(false) // Set to false by default
  const [hasMoneyImage, setHasMoneyImage] = useState(false) // Set to false by default
  
  // Simulate character with CSS if image is missing
  const CharacterFallback = () => (
    <div className="relative w-[280px] h-[400px] md:w-[380px] md:h-[500px] flex items-center justify-center">
      <div className="w-[70%] h-[70%] rounded-t-full bg-purple-300 relative flex flex-col items-center justify-center">
        <div className="absolute top-[25%] left-[20%] w-12 h-12 rounded-full bg-white">
          <div className="w-6 h-6 rounded-full bg-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        </div>
        <div className="absolute top-[25%] right-[20%] w-12 h-12 rounded-full bg-white">
          <div className="w-6 h-6 rounded-full bg-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        </div>
        <div className="absolute top-[60%] w-10 h-5 rounded-full bg-pink-200"></div>
        <div className="absolute top-[70%] w-16 h-6 rounded-full bg-pink-300"></div>
        <div className="absolute bottom-[-40%] w-[120%] h-[40%] bg-blue-400 rounded-lg"></div>
      </div>
    </div>
  )

  return (
    <section className="relative h-screen overflow-hidden">
      {/* Background Image with Overlay */}
      <div className="absolute inset-0 z-0">
        <img 
          src="/2150149032.jpg" 
          alt="Background" 
          className="object-cover w-full h-full"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-purple-900/70 via-blue-800/50 to-pink-800/60"></div>
      </div>

      {/* Hero Content */}
      <div className="relative z-20 h-full pt-20 md:pt-24">
        <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-20 h-full">
          <div className="flex flex-col md:flex-row items-center justify-between h-[calc(100%-5rem)]">
            {/* Left Column - Character */}
            <div className="md:w-1/2 flex justify-center md:justify-start order-2 md:order-1">
              <CharacterFallback />
            </div>

            {/* Right Column - Text Content */}
            <div className="md:w-1/2 space-y-6 md:space-y-8 text-center md:text-left mt-8 md:mt-0 order-1 md:order-2">
              <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white leading-tight tracking-tight">
                DISCOVER THE ULTIMATE FREE SOCIAL CASINO EXPERIENCE
              </h1>
              <p className="text-base md:text-lg text-white/90 max-w-xl">
                Step into a world of endless excitement where you can compete, win, and connect with players worldwide. Our platform delivers captivating games, stunning visuals, and rewarding gameplay for all experience levels.
              </p>
              <div className="pt-4">
                <button className="bg-gradient-to-r from-pink-500 to-purple-600 hover:from-pink-600 hover:to-purple-700 text-white px-8 py-3 rounded-full transition-all duration-300 transform hover:scale-105 shadow-lg font-medium text-lg">
                  Start Playing Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Floating Elements - Simple Divs */}
      <div className="absolute bottom-20 right-20 z-10 hidden md:block">
        <div className="w-16 h-16 rounded-full bg-blue-500/30 backdrop-blur-md"></div>
      </div>
      <div className="absolute top-40 left-20 z-10 hidden md:block">
        <div className="w-24 h-24 rounded-full bg-pink-500/20 backdrop-blur-md"></div>
      </div>
      
      {/* Floating Money */}
      <div className="absolute top-1/4 right-1/3 z-10 hidden md:block">
        {hasMoneyImage ? (
          <img src="/floating-money.png" alt="Floating Money" className="w-16 h-16 animate-float" />
        ) : (
          <div className="w-16 h-16 rounded-lg bg-green-400/20 backdrop-blur-md animate-float"></div>
        )}
      </div>
    </section>
  );
};

export default HeroSection; 