import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import contactContent from '../contents/contact.json';

function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Us - Game-IO Free Social Casino</title>
        <meta name="description" content="Have questions about Game-IO? Get in touch with our team through our contact form or find our business information. We're here to help with any inquiries." />
        <meta property="og:title" content="Contact Us - Game-IO Free Social Casino" />
        <meta property="og:description" content="Have questions about Game-IO? Get in touch with our team through our contact form or find our business information. We're here to help with any inquiries." />
        <meta property="og:url" content="https://game-io.com/contact" />
        <meta property="og:type" content="website" />
      </Helmet>
      
      <main className="min-h-screen pt-24 bg-indigo-500">
        <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-20 py-16 md:py-24">
          <div className="text-center mb-16">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-yellow-300 mb-6">
              {contactContent.title}
            </h1>
            <p className="text-white text-lg max-w-3xl mx-auto">
              {contactContent.description}
            </p>
          </div>
          
          <div className="bg-indigo-600 rounded-2xl p-8 md:p-12 shadow-xl border-2 border-yellow-300 mb-16">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {contactContent.contactInfo.map((info, index) => (
                <div key={index} className="bg-indigo-700/50 p-6 rounded-xl">
                  <h3 className="text-xl font-bold text-yellow-300 mb-4">{info.title}</h3>
                  <p className="text-white mb-6">{info.text}</p>
                  <a 
                    href={`mailto:${contactContent.email}?subject=${info.title}`}
                    className="inline-flex items-center text-yellow-300 hover:text-yellow-100 transition-colors"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    {contactContent.emailLabel}
                  </a>
                </div>
              ))}
            </div>
          </div>
          
          <div className="bg-indigo-600 rounded-2xl p-8 md:p-12 shadow-xl border-2 border-dashed border-white">
            <h2 className="text-2xl md:text-3xl font-bold text-yellow-300 mb-8 text-center">Direct Contact</h2>
            
            <div className="max-w-xl mx-auto">
              <div className="text-center p-6 bg-indigo-700/50 rounded-xl mb-6">
                <h3 className="text-xl font-bold text-white mb-2">Email Us</h3>
                <a 
                  href={`mailto:${contactContent.email}`}
                  className="text-yellow-300 hover:text-yellow-100 text-xl transition-colors"
                >
                  {contactContent.email}
                </a>
              </div>
              
              <p className="text-white text-center">
                We typically respond to all inquiries within 24-48 hours during business days.
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Contact; 