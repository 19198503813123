import React from 'react';
import { Helmet } from 'react-helmet-async';

function Privacy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Game-IO Free Social Casino</title>
        <meta name="description" content="Read our Privacy Policy to understand how Game-IO handles your personal information, data collection practices, and your privacy rights when using our platform." />
        <meta property="og:title" content="Privacy Policy - Game-IO Free Social Casino" />
        <meta property="og:description" content="Read our Privacy Policy to understand how Game-IO handles your personal information, data collection practices, and your privacy rights when using our platform." />
        <meta property="og:url" content="https://game-io.com/privacy" />
        <meta property="og:type" content="website" />
      </Helmet>
      
      <main className="min-h-screen bg-indigo-600 pt-24">
        <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-20 py-16 md:py-24">
          <div className="text-center mb-12">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-yellow-300 mb-6">
              Privacy Policy
            </h1>
            <p className="text-white text-lg max-w-3xl mx-auto">
              Last Updated: March 24, 2025
            </p>
          </div>
          
          <div className="bg-white/10 rounded-2xl backdrop-blur-sm p-8 md:p-12 text-white shadow-xl border border-white/10">
            <div className="prose prose-lg prose-invert max-w-none">
              <p>
                At Game-IO, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
              </p>
              
              <h2>Information We Collect</h2>
              <p>
                We may collect personal information that you voluntarily provide to us when you register on the website, express interest in obtaining information about us or our products and services, or otherwise contact us.
              </p>
              <p>
                The personal information we collect may include:
              </p>
              <ul>
                <li>Name and contact information</li>
                <li>Email address</li>
                <li>User preferences and game history</li>
                <li>Information you provide in public forums on our site</li>
                <li>Device information and IP address</li>
              </ul>
              
              <h2>How We Use Your Information</h2>
              <p>
                We may use the information we collect from you for various purposes, including:
              </p>
              <ul>
                <li>To provide and maintain our service</li>
                <li>To notify you about changes to our service</li>
                <li>To provide customer support</li>
                <li>To gather analysis or valuable information so that we can improve our service</li>
                <li>To monitor the usage of our service</li>
                <li>To detect, prevent and address technical issues</li>
              </ul>
              
              <h2>Disclosure of Your Information</h2>
              <p>
                We may share your information with third parties only in the ways that are described in this Privacy Policy:
              </p>
              <ul>
                <li>With service providers who perform services for us</li>
                <li>To comply with legal obligations</li>
                <li>To protect and defend our rights and property</li>
                <li>With your consent or at your direction</li>
              </ul>
              
              <h2>Your Choices</h2>
              <p>
                You can choose not to provide certain information, but this may limit your ability to use certain features of our service. You can also opt-out of receiving marketing communications from us by following the unsubscribe instructions included in these communications.
              </p>
              
              <h2>Contact Us</h2>
              <p>
                If you have questions about this Privacy Policy, please contact us at privacy@game-io.com.
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Privacy; 