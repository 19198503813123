import React from 'react';

// Mock of disclaimerContent from the JSON file
const disclaimerContent = {
  title: "DISCLAIMER",
  text: "Players who are 18 years of age or older are the intended player base for these free social casino games, which are solely meant to be entertaining. It's crucial to remember that none of the free social casino games involve No Genuine Transactions, making certain that every free social casino gamer may have fun in an appropriate atmosphere."
};

const DisclaimerSection = () => {
  return (
    <section className="py-16 relative overflow-hidden">
      {/* Modern Background Elements */}
      <div className="absolute inset-0 bg-gradient-to-br from-slate-900 via-indigo-900 to-slate-800 z-0"></div>
      
      {/* Grid Pattern */}
      <div className="absolute inset-0 z-0 opacity-5">
        <div className="h-full w-full"
             style={{ 
               backgroundImage: 'linear-gradient(to right, white 1px, transparent 1px), linear-gradient(to bottom, white 1px, transparent 1px)',
               backgroundSize: '40px 40px'
             }}>
        </div>
      </div>
      
      {/* Floating Elements */}
      <div className="absolute top-[5%] right-[15%] w-56 h-56 rounded-full bg-blue-500 opacity-20 blur-3xl animate-float"></div>
      <div className="absolute bottom-[10%] left-[10%] w-72 h-72 rounded-full bg-purple-500 opacity-15 blur-3xl animate-float-slow"></div>
      <div className="absolute top-[30%] left-[20%] w-36 h-36 rounded-full bg-cyan-500 opacity-10 blur-2xl animate-blob"></div>
      
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-20 relative z-10">
        <div className="backdrop-blur-lg bg-indigo-600/30 rounded-2xl p-8 md:p-10 shadow-2xl border border-white/10 relative overflow-hidden">
          
          {/* Background decorative elements */}
          <div className="absolute top-0 right-0 w-64 h-64 bg-indigo-500 rounded-full -translate-y-1/2 translate-x-1/2 opacity-20 blur-2xl"></div>
          <div className="absolute bottom-0 left-0 w-48 h-48 bg-purple-500 rounded-full translate-y-1/2 -translate-x-1/2 opacity-20 blur-xl"></div>
          
          <div className="flex flex-col md:flex-row items-center gap-8 relative z-10">
            {/* Left Side - Icon */}
            <div className="md:w-1/4 flex justify-center">
              <div className="relative w-40 h-40 md:w-52 md:h-52">
                <div className="absolute inset-0 bg-red-500 rounded-full animate-pulse-slow opacity-30"></div>
                <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-700 rounded-full blur-md opacity-40 animate-pulse-slow"></div>
                <div className="relative flex items-center justify-center h-full">
                  <div className="bg-white/90 backdrop-blur-sm rounded-full p-2 shadow-lg border border-white/50">
                    <div className="relative w-32 h-32 md:w-40 md:h-40">
                      <div className="w-full h-full bg-white rounded-full flex items-center justify-center relative overflow-hidden">
                        <div className="absolute w-full h-full flex items-center justify-center">
                          <div className="w-full h-full bg-gradient-to-br from-red-500 to-red-700 rounded-full absolute"></div>
                          <div className="w-[90%] h-[90%] bg-white rounded-full absolute"></div>
                          <div className="absolute w-[80%] h-1 bg-red-500 rotate-45"></div>
                          <div className="absolute w-[80%] h-1 bg-red-500 -rotate-45"></div>
                          <div className="absolute w-full h-[2px] bg-red-500"></div>
                          <div className="text-[80px] font-bold text-red-500 opacity-80">18</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Right Side - Content */}
            <div className="md:w-3/4">
              <h2 className="text-3xl md:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-yellow-500 mb-6 text-center md:text-left">
                {disclaimerContent.title}
              </h2>
              <div className="text-white text-base md:text-lg leading-relaxed backdrop-blur-md bg-indigo-800/40 p-6 rounded-xl border border-indigo-400/30 shadow-xl">
                {disclaimerContent.text}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DisclaimerSection; 