import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import GameDetail from '../components/GameDetail';
import GamesSection from '../components/sections/GamesSection';
import DisclaimerSection from '../components/sections/DisclaimerSection';
import gamesContent from '../contents/games.json';

function GameDetailPage() {
  const { gameId } = useParams();
  
  // Find the game data
  const game = gameId 
    ? gamesContent.featuredGames.find(g => g.id === gameId) 
    : null;
    
  const title = game 
    ? `${game.name} - Game-IO Free Social Casino` 
    : 'Game Not Found - Game-IO';
    
  const description = game
    ? `Play ${game.name} at Game-IO - the ultimate free social casino experience. Enjoy free-to-play games with friends!`
    : 'The requested game could not be found.';
    
  const imageUrl = game?.image || '';
  
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`https://game-io.com/games/${gameId}`} />
        <meta property="og:site_name" content="Game-IO Free Social Casino" />
        {imageUrl && (
          <meta property="og:image" content={imageUrl} />
        )}
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="pt-0">
        <GameDetail gameId={gameId} />
      </div>
    </>
  );
}

function GamesList() {
  return (
    <>
      <Helmet>
        <title>Games - Game-IO Free Social Casino</title>
        <meta name="description" content="Browse and play our complete collection of free social casino games. Enjoy slots, poker, and more - all free to play!" />
        <meta property="og:title" content="Games - Game-IO Free Social Casino" />
        <meta property="og:description" content="Browse and play our complete collection of free social casino games. Enjoy slots, poker, and more - all free to play!" />
        <meta property="og:url" content="https://game-io.com/games" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="bg-indigo-800 py-20 px-6 md:px-12 xl:px-20 pt-24">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-yellow-300 mb-6 text-center">
            Our Games
          </h1>
          <p className="text-white text-lg max-w-3xl mx-auto text-center">
            Browse our complete collection of free social casino games and start playing today
          </p>
        </div>
      </div>
      
      <GamesSection />
      <DisclaimerSection />
    </>
  );
}

function Games() {
  return (
    <Routes>
      <Route path="/" element={<GamesList />} />
      <Route path=":gameId" element={<GameDetailPage />} />
    </Routes>
  );
}

export default Games; 