import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    // Check if user has already made a choice
    const consentGiven = localStorage.getItem('cookieConsent');
    if (consentGiven === null) {
      // Show the consent banner after a short delay
      const timer = setTimeout(() => {
        setShowConsent(true);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowConsent(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setShowConsent(false);
  };

  if (!showConsent) return null;

  return (
    <div className="fixed bottom-4 md:bottom-8 right-4 md:right-8 z-50 max-w-md">
      <div className="bg-indigo-900/90 backdrop-blur-lg rounded-2xl border border-white/20 shadow-2xl p-6 animate-fade-in-up">
        <div className="flex items-start mb-4">
          <div className="mr-3 mt-0.5">
            <svg className="w-7 h-7 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
              <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path>
            </svg>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-white mb-2">Cookie Notice</h3>
            <p className="text-sm text-white/80 leading-relaxed">
              We use cookies to enhance your experience on our site. By continuing to use our site, you consent to our use of cookies.
            </p>
          </div>
        </div>
        
        <div className="flex flex-col sm:flex-row gap-3 sm:justify-end">
          <button 
            onClick={handleDecline}
            className="px-4 py-2 bg-white/10 hover:bg-white/20 text-white rounded-lg transition-colors text-sm"
          >
            Decline
          </button>
          <button 
            onClick={handleAccept}
            className="px-4 py-2 bg-gradient-to-r from-yellow-400 to-yellow-500 hover:from-yellow-500 hover:to-yellow-600 text-indigo-900 font-medium rounded-lg transition-colors text-sm"
          >
            Accept All Cookies
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent; 