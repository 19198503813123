import React from 'react';
import { Helmet } from 'react-helmet-async';
import AboutHero from '../components/sections/AboutHero';
import AboutSection from '../components/sections/AboutSection';
import DisclaimerSection from '../components/sections/DisclaimerSection';

function About() {
  return (
    <>
      <Helmet>
        <title>About Us - Game-IO Free Social Casino</title>
        <meta name="description" content="Learn about Game-IO, the ultimate free social casino gaming platform. Discover our story, mission, and commitment to providing the best gaming experience." />
        <meta property="og:title" content="About Us - Game-IO Free Social Casino" />
        <meta property="og:description" content="Learn about Game-IO, the ultimate free social casino gaming platform. Discover our story, mission, and commitment to providing the best gaming experience." />
        <meta property="og:url" content="https://game-io.com/about" />
        <meta property="og:type" content="website" />
      </Helmet>
      <AboutHero />
      <AboutSection />
      <DisclaimerSection />
    </>
  );
}

export default About; 