import React from 'react';
import { Link } from 'react-router-dom';

// Mock of footerContent from the JSON file
const footerContent = {
  copyright: "Copyright © Game-IO 2025. All Rights Reserved",
  links: [
    {
      text: "Privacy Policy",
      href: "/privacy"
    },
    {
      text: "Terms And Conditions",
      href: "/terms"
    },
    {
      text: "Contact Us",
      href: "/contact"
    }
  ],
  socialMedia: [
    {
      name: "Facebook",
      icon: "facebook",
      href: "#"
    },
    {
      name: "Twitter",
      icon: "twitter",
      href: "#"
    },
    {
      name: "Instagram",
      icon: "instagram",
      href: "#"
    }
  ]
};

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-indigo-700 text-white">
      {/* Top section with logo and links */}
      <div className="max-w-7xl mx-auto pt-16 pb-8 px-6 md:px-12 xl:px-20">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
          {/* Brand column */}
          <div className="md:col-span-1">
            <div className="flex justify-start md:justify-center">
              <Link to="/">
                <img 
                  src="/logo.png" 
                  alt="GAME-IO CASINO" 
                  className="object-contain h-14"
                />
              </Link>
            </div>
            <p className="mt-6 text-indigo-200 text-sm max-w-xs">
              Enjoy the best free social casino games with our platform. Fun and entertainment guaranteed!
            </p>
          </div>

          {/* Links columns */}
          <div className="md:col-span-2 grid grid-cols-2 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4 text-yellow-300">Quick Links</h3>
              <ul className="space-y-3">
                <li><Link to="/" className="text-indigo-200 hover:text-white transition-colors">Home</Link></li>
                <li><Link to="/about" className="text-indigo-200 hover:text-white transition-colors">About</Link></li>
                <li><Link to="/games" className="text-indigo-200 hover:text-white transition-colors">Games</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4 text-yellow-300">Legal</h3>
              <ul className="space-y-3">
                {footerContent.links.map((link, index) => (
                  <li key={index}>
                    <Link 
                      to={link.href} 
                      className="text-indigo-200 hover:text-white transition-colors"
                    >
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Contact info column */}
          <div className="md:col-span-1">
            <h3 className="text-lg font-semibold mb-4 text-yellow-300">Contact Us</h3>
            <p className="text-indigo-200 text-sm">
              Questions? <br/>
              <a href="mailto:info@game-io.com" className="text-yellow-300 hover:underline">info@game-io.com</a>
            </p>
          </div>
        </div>
      </div>

      {/* Bottom section with copyright and terms */}
      <div className="border-t border-indigo-600 py-6">
        <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-20 flex flex-col md:flex-row justify-between items-center">
          <p className="text-indigo-300 text-sm mb-4 md:mb-0">
            {footerContent.copyright.replace('2025', currentYear.toString())}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 