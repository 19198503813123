import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import DisclaimerSection from './sections/DisclaimerSection';
import GameIframe from './GameIframe';
import gamesContent from '../contents/games.json';

interface GameDetailProps {
  gameId?: string;
}

const GameDetail: React.FC<GameDetailProps> = ({ gameId }) => {
  const [imageError, setImageError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const iframeRef = useRef<HTMLDivElement>(null);
  
  // Find the game in our content
  const game = gameId 
    ? gamesContent.featuredGames.find(g => g.id === gameId) 
    : null;

  // If game not found, use a default placeholder
  const gameData = game || {
    id: 'default',
    name: 'Game',
    image: '/games/default.webp',
    bg: 'bg-purple-600',
    iframe: ''
  };
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 300);
    
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      clearTimeout(timer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const scrollToGame = () => {
    if (iframeRef.current) {
      iframeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className={`relative overflow-hidden`}>
        {/* Background elements - changed from fixed to absolute positioning */}
        <div className="absolute inset-0 z-0">
          <div className={`absolute inset-0 ${gameData.bg} opacity-70`}></div>
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-black/40"></div>
          
          {/* Grid pattern */}
          <div className="absolute inset-0 opacity-5">
            <div className="h-full w-full"
                style={{ 
                  backgroundImage: 'linear-gradient(to right, white 1px, transparent 1px), linear-gradient(to bottom, white 1px, transparent 1px)',
                  backgroundSize: '40px 40px',
                  transform: `translateY(${scrollPosition * 0.1}px)`
                }}>
            </div>
          </div>
          
          {/* Floating elements - parallax effect */}
          <div 
            className="absolute top-[10%] left-[10%] w-48 h-48 rounded-full bg-purple-500 opacity-20 blur-3xl" 
            style={{ transform: `translate(${scrollPosition * 0.05}px, ${scrollPosition * -0.05}px)` }}
          ></div>
          <div 
            className="absolute top-[40%] right-[15%] w-56 h-56 rounded-full bg-blue-500 opacity-20 blur-3xl" 
            style={{ transform: `translate(${scrollPosition * -0.07}px, ${scrollPosition * 0.03}px)` }}
          ></div>
          <div 
            className="absolute bottom-[20%] left-[25%] w-64 h-64 rounded-full bg-pink-500 opacity-20 blur-3xl" 
            style={{ transform: `translate(${scrollPosition * 0.06}px, ${scrollPosition * -0.04}px)` }}
          ></div>
        </div>
        
        {/* Content */}
        <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-20 relative z-10">
          {/* Back button */}
          <div className="flex justify-between items-center py-8">
            <Link 
              to="/games" 
              className={`group relative inline-flex items-center text-white hover:text-yellow-300 transition-all duration-500 px-5 py-2.5 ${isLoaded ? 'opacity-100' : 'opacity-0 -translate-x-5'}`}
            >
              {/* Button background */}
              <span className="absolute inset-0 w-full h-full transition-all duration-300 bg-white/10 backdrop-blur-md border border-white/20 rounded-full group-hover:bg-white/15"></span>
              
              {/* Glow effect */}
              <span className="absolute inset-0 w-full h-full transition-all duration-500 rounded-full opacity-0 group-hover:opacity-100 blur-lg bg-gradient-to-r from-blue-500/30 to-purple-500/30"></span>
              
              <svg xmlns="http://www.w3.org/2000/svg" className="relative z-10 h-5 w-5 mr-2 transition-transform duration-300 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              <span className="relative z-10">Back to Games</span>
            </Link>
            
            {/* Play now button - only shows if game has iframe */}
            {gameData.iframe && (
              <button 
                onClick={scrollToGame}
                className={`group relative inline-flex items-center bg-gradient-to-r from-green-500 to-emerald-600 text-white font-medium px-6 py-2.5 rounded-full shadow-lg transition-all duration-300 hover:shadow-emerald-500/30 ${isLoaded ? 'opacity-100' : 'opacity-0 translate-x-5'}`}
              >
                {/* Button shine */}
                <span className="absolute inset-0 w-full h-full rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                  <span className="absolute inset-0 w-1/3 h-full bg-white/20 blur-sm transform -skew-x-12 bg-gradient-to-r from-transparent via-white/20 to-transparent group-hover:animate-shimmer"></span>
                </span>
                
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                </svg>
                <span>Play Now</span>
              </button>
            )}
          </div>
          
          {/* Game info card */}
          <div className={`backdrop-blur-md bg-white/5 rounded-3xl overflow-hidden border border-white/20 shadow-2xl transition-all duration-1000 ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
            {/* Game header section */}
            <div className="relative h-64 md:h-80 lg:h-96 overflow-hidden">
              {/* Game banner bg - blurred version of the game image */}
              <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
                {!imageError ? (
                  <img
                    src={gameData.image}
                    alt={`${gameData.name} Background`}
                    className="w-full h-full object-cover scale-110 blur-md"
                    onError={() => setImageError(true)}
                  />
                ) : (
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-800 to-pink-700"></div>
                )}
                <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/70"></div>
              </div>
              
              {/* Game title overlay */}
              <div className="absolute inset-0 flex flex-col items-center justify-center p-6 text-center z-10">
                <h1 
                  className="text-5xl md:text-6xl lg:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-yellow-500 mb-4"
                  style={{ transform: `translateY(${scrollPosition * -0.1}px)` }}
                >
                  {gameData.name}
                </h1>
                <div className="w-24 h-1 bg-gradient-to-r from-yellow-400 to-transparent rounded-full mb-6"></div>
                <p 
                  className="text-white text-lg md:text-xl max-w-2xl"
                  style={{ transform: `translateY(${scrollPosition * -0.05}px)` }}
                >
                  Experience the thrill of {gameData.name} - one of Game-IO's most popular free social casino games!
                </p>
                
                {/* Animated indicators */}
                {gameData.iframe && (
                  <div 
                    className="mt-8 flex gap-2 items-center"
                    style={{ transform: `translateY(${scrollPosition * -0.15}px)` }}
                  >
                    <span 
                      className="text-white/70 text-sm animate-pulse"
                      onClick={scrollToGame}
                    >
                      Scroll down to play
                    </span>
                    <div className="flex gap-1">
                      <div className="w-1.5 h-6 bg-gradient-to-b from-yellow-300 to-yellow-500 rounded-full animate-bounce-slow"></div>
                      <div className="w-1.5 h-8 bg-gradient-to-b from-yellow-300 to-yellow-500 rounded-full animate-bounce-delayed"></div>
                      <div className="w-1.5 h-4 bg-gradient-to-b from-yellow-300 to-yellow-500 rounded-full animate-bounce-slower"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            
            {/* Game iframe container */}
            {gameData.iframe && (
              <div 
                ref={iframeRef}
                id="gameIframe" 
                className={`p-8 md:p-12 bg-gradient-to-b from-purple-900/30 to-indigo-900/30 backdrop-blur-md border-t border-white/10 transition-all duration-1000 delay-300 ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
              >
                <div className="max-w-5xl mx-auto">
                  <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-yellow-500 mb-6 text-center">
                    Play {gameData.name}
                  </h2>
                  
                  <div className="relative backdrop-blur-sm border-4 border-white/20 rounded-2xl overflow-hidden shadow-2xl">
                    {/* Glow effect */}
                    <div className="absolute -inset-1 bg-gradient-to-r from-pink-500 to-purple-600 opacity-50 blur-lg"></div>
                    
                    {/* Corner decorations */}
                    <div className="absolute top-0 left-0 w-16 h-16 border-t-4 border-l-4 border-yellow-400/50 rounded-tl-xl"></div>
                    <div className="absolute top-0 right-0 w-16 h-16 border-t-4 border-r-4 border-yellow-400/50 rounded-tr-xl"></div>
                    <div className="absolute bottom-0 left-0 w-16 h-16 border-b-4 border-l-4 border-yellow-400/50 rounded-bl-xl"></div>
                    <div className="absolute bottom-0 right-0 w-16 h-16 border-b-4 border-r-4 border-yellow-400/50 rounded-br-xl"></div>
                    
                    <div className="relative z-10">
                      <GameIframe src={gameData.iframe} title={`Play ${gameData.name}`} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          
          {/* Add margin at the bottom to create space before the footer */}
          <div className="h-20"></div>
        </div>
      </div>
      <DisclaimerSection />
    </>
  );
};

export default GameDetail; 